import Footer from "../../Components/Landing/Footer/Footer";
import ProxyCard from "../../Components/Landing/ProxyCard/ProxyCard";
import DetailsCard from "../../Components/Landing/DetailsCard/DetailsCard";
import "./ProductDetails.scss";

import products from '../../data/products';
import ServerCard from "../../Components/Landing/ServerCard/ServerCard";
import { Helmet } from "react-helmet";

function ProductDetails({ product }) {

    const page = product.path == "servers" ? products.serverDetails : product;

    return (
        <>
            <Helmet>
                <title>{page.title}</title>
                <meta name="description"
                    content={page.description}></meta>
                <meta property="og:type" content="website"></meta>
                <meta property="og:title" content={page.title}></meta>
                <meta property="og:description"
                    content={page.description}></meta>
                <meta property="twitter:title" content={page.title}></meta>
                <meta property="twitter:description"
                    content={page.description}></meta>
            </Helmet>
            <div className="product-details-container">
                {
                    product && product.path == "servers"
                        ?
                        <>
                            <ServerCard value={products.servers} extended />
                            <DetailsCard value={products.serverDetails} />
                        </>
                        :
                        <>
                            <ProxyCard key={product.id} value={product} extended />
                            <DetailsCard value={product.details} />
                        </>
                }
            </div>
            <Footer />
        </>
    )
}

export default ProductDetails;