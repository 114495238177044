import TrustBox from "../Components/Landing/TrustBox/TrustBox";
import { useLocation, useOrientation } from 'react-use';

import "./Home.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { ReactComponent as EnterpriseIcon } from '../assets/icons/enterprise.svg';

import backgroundSingle from "../assets/images/background-single.webp";

import products from '../data/products';
import reviews from '../data/reviews';

import React, { useEffect, useState } from "react";
import ProxyCard from "../Components/Landing/ProxyCard/ProxyCard";
import ServerCard from "../Components/Landing/ServerCard/ServerCard";
import Analytics from "../Components/Landing/Analytics/Analytics";
import ImageCard from "../Components/Landing/ImageCard/ImageCard";
import ReviewCard from "../Components/Landing/ReviewCard/ReviewCard";
import { Carousel } from "react-responsive-carousel";
import ContactForm from "../Components/Landing/ContactForm/ContactForm";
import Calendly from "../Components/Landing/Calendly/Calendly";
import Footer from "../Components/Landing/Footer/Footer";
import { useNavigate } from "react-router-dom";

function Home() {
    const navigate = useNavigate();
    const location = useLocation();

    const pathMapping = {
        "contact": {
            hash: "contact",
            title: "Get In Touch - Primed Proxies",
            description: "Feel free to book in a sales call or send us a message at any time, and we'll be delighted to respond to you as promptly as we can."
        }
    }

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [page, setPage] = useState();

    const { type } = useOrientation();

    function handleShowDetails(item) {
        console.log(item);
        navigate(`/${item.path}`)
    }

    function handleReachedMax(proxy) {
        if (proxy === "Residential") {
            document.getElementsByClassName("enterprise-note")[0].classList.add("shake");
            setTimeout(() => document.getElementsByClassName("enterprise-note")[0].classList.remove("shake"), 350);
        }
    }

    function handleViewAllProducts() {
        scrollToElement("products");
    }

    let width = window.innerWidth;

    useEffect(() => {
        let path = location.pathname.replace('/', '').toLowerCase();
        let map = pathMapping[path];

        if (map) {
            setPage(map);

            setTimeout(() => {
                scrollToElement(map.hash);
            });
        } else {
            const elementId = location.hash.replace('#', '');
            if (elementId) {
                console.log("SCROLLING: " + elementId);
                scrollToElement(elementId);
            }
        }
    }, [location]);

    return (
        <>
            <div className="main">
                <div className="section-1">
                    <img className="background" alt="background" src={type == "landscape-primary" ? "/images/background-main.webp" : "/images/m-background-main.webp"} />
                    <div className="section-1-body">
                        <TrustBox />
                        <div className="section-1-content">
                            <div className="section-1-text" data-aos="fade-up" data-aos-duration="1000">
                                <h1>The best Residential and ISP<br /> Proxies in the game.</h1>
                                <h5>High-quality data collection infrastructure for any application. Scale your public data gathering using a 45M+ IP proxy pool renowned for its top-tier quality.</h5>
                                <button onClick={handleViewAllProducts}>View all Products</button>
                            </div>
                        </div>
                        <img className="section-header" alt="dashboard-preview" src={type == "landscape-primary" ? "/images/section1-header.webp" : "/images/m-section1-header.webp"} />
                    </div>
                    <div className="rect-2"></div>
                </div>
                <div className="section-2">
                    <div className="background-container">
                        <img className="background-single" alt="background" src={backgroundSingle} />
                    </div>
                    <div id="products" className="chip-header">
                        <p>Our products</p>
                    </div>
                    <h3>Uninterrupted browsing through our ready-to-go infrastructure.</h3>
                    <span className="header-description" style={{ 'max-width': '700px' }}>Select your product below and receive delivery instantly to our Dashboard - accessible after purchase</span>
                    <div className="products">
                        <div className="proxies-container">
                            {
                                products.proxies.map(element => (
                                    <ProxyCard key={element.id} value={element} onToggle={handleShowDetails} onReachedMax={handleReachedMax} extended />
                                ))
                            }
                            <ServerCard value={products.servers} onToggle={handleShowDetails} extended />
                        </div>
                    </div>
                    <div className="enterprise-note">
                        <EnterpriseIcon />
                        <span>Need more than 100GB of Residential Data? Book a call with our sales team for enterprise quotes <a href="#contact">here</a>.</span>
                    </div>
                </div>
                <div className="section-3">
                    <Analytics />
                </div>
                <div id="features" className="section-4">
                    <div className="background-container">
                        <img className="background-single" alt="background" src={backgroundSingle} />
                    </div>
                    <div className="chip-header">
                        <p>Primed features</p>
                    </div>
                    <h3>An array of use-cases at your fingertips</h3>
                    <span className="header-description">Through our products, we will assist you in your use-case to stay competitive, and obtain valuable insights through our network.</span>
                    {/* <button className="redirect-button">
                    Learn more
                    <ArrowRightIcon />
                </button> */}
                    <div className="feature-container">
                        <ImageCard image={require(`../assets/icons/features/feature1.svg`).default} title="Enhance market research" description={`Perform consistent market data collection\nand research to outpace competitors.`} />
                        <ImageCard image={require(`../assets/icons/features/feature2.svg`).default} title="Scale e-commerce effectively" description={`Gather the most precise publicly accessible web data for a\n sustained competitive advantage.`} />
                        <ImageCard image={require(`../assets/icons/features/feature3.svg`).default} title="Dive into web scraping" description={`Forget getting blocked and unlock valuable\n public data in any location.`} />
                        <ImageCard image={require(`../assets/icons/features/feature4.svg`).default} title="Streamline your AdTech" description={`Utilize our geo-targeting capabilities for testing ads, optimizing\n CPA, and verifying links using high-quality IPs.`} />
                        <ImageCard image={require(`../assets/icons/features/feature5.webp`)} width="398px" title="Efficient social media management" description={`Create and manage multiple social media\n accounts and access local content.`} />
                        <ImageCard image={require(`../assets/icons/features/feature6.webp`)} width="298px" margin="10px" title="Purchase limited sneakers" description={`Secure the most coveted releases\n with the best proxy speeds.`} />
                    </div>
                </div>
                <div id="reviews" className="section-5">
                    <div className="background-container">
                        <img className="background-single" alt="background" src={backgroundSingle} />
                    </div>
                    <div className="chip-header">
                        <p>A host of happy clients</p>
                    </div>
                    <h3>This is what our clients think about our business</h3>
                    {
                        width > 992
                            ?
                            <div className="review-container">
                                {
                                    reviews.map(review => (
                                        <ReviewCard key={review.name} value={review} extra={review.extra} />
                                    ))
                                }
                            </div>
                            :
                            <Carousel showArrows={false} showThumbs={false} showStatus={false} dynamicHeight={false} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={25}>
                                {
                                    reviews.map(review =>
                                        <div key={review} className="carousel-item">
                                            <ReviewCard value={review} extra={review.extra} />
                                        </div>
                                    )
                                }
                            </Carousel>
                    }
                </div>
                <div id="contact" className="section-6">
                    <div className="background-container">
                        <img className="background-single" alt="background" src={backgroundSingle} />
                    </div>
                    <h3>Get in touch</h3>
                    <span className="header-description">Feel free to book in a sales call or send us a message at any time, and we'll be delighted to respond to you as promptly as we can</span>
                    <div className="contact-container">
                        <ContactForm />
                        <Calendly />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Home;